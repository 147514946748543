import Popup from 'reactjs-popup'
import styled from 'styled-components'

export const StyledPopup = styled(Popup)``

export const Container = styled.div``

export const Content = styled.div<{
	flex?: boolean
}>`
	${props => (props.flex ? 'display: flex;' : '')};
	margin-left: 5px;
`
export const Info = styled.img``
