import React from 'react'
import ReactTooltip from 'react-tooltip'

// @ts-ignore
import info from '@cuidardigital/commons/assets/svg/icon-info.svg'
import { Container, Content, Info } from './styles'

interface Props {
	id?: string
	place?: 'top' | 'right' | 'bottom' | 'left'
	type?: 'dark' | 'success' | 'warning' | 'error' | 'info' | 'light'
	effect?: 'float' | 'solid'
	content: string
	flex?: boolean
	children?: any
}

const Tooltip: React.FC<Props> = ({ id, place, type, effect, content, flex, children }) => {
	return (
		<Container id={id}>
			<Content flex={flex} data-tip={content} data-event='click focus'>
				<Info src={info} />
			</Content>

			<ReactTooltip globalEventOff='click' place={place} type={type} effect={effect}>
				{children}
			</ReactTooltip>
		</Container>
	)
}

Tooltip.defaultProps = {
	place: 'top',
	type: 'dark',
	effect: 'float',
	content: 'Insert a tooltip here :)',
	flex: false
}

export default Tooltip
